import { isEmpty } from 'lodash';
import { ISettingItem } from '../../../monitoring/models/components/ISettingItem';
import { IItemListViewMapper } from '../models/IItemListViewMapper';
import { splitDataToCharts } from './global';

const sideEffectsToChartViewMapper = (
  observationData: IItemListViewMapper[],
  sideEffect: ISettingItem
) => {
  if (isEmpty(observationData) || isEmpty(sideEffect)) return null;
  const { possibleValues } = sideEffect;

  const { lineSeries, markSeries }: any =
    !isEmpty(observationData) && splitDataToCharts(observationData);
  const chartDataMapper = {
    lineSeries: lineSeries,
    markSeries,
    config: {
      double: false,
      possibleValues,
      step: 1,
      min: Math.min(...possibleValues.map((item: any) => item.value)),
      max: Math.max(...possibleValues.map((item: any) => item.value)),
      isSideEffect: true
    }
  };
  return chartDataMapper;
};

export default sideEffectsToChartViewMapper;
