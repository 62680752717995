import React, { FC } from 'react';
import { Table } from 'antd';
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import casesTableTemplate from '../../../templates/tables/casesTableTemplate';
import casesSkeletonTableTemplate from '../../../templates/tables/casesSkeletonTableTemplate';
import { ICasesTable } from '../../../models/components/ICasesTable';
import { useTranslation } from 'react-i18next';
import settings from '../../../config/settings';
import {
  expandedRowKeys,
  mapDescriptionRow
} from '../../OnkoTableExpandesRows/OnkoTableExpandesRows';
import { isEmpty } from 'lodash';
import CasesWarningBanners from '../../CasesWarningBanners/CasesWarningBanners';

const CasesTable: FC<ICasesTable> = ({
  patientCareStore,
  medicationTherapyStore,
  navigateToLink
}): JSX.Element => {
  const heightDistance = settings.casesTableHeight;
  const [height, setHeight] = useState(window.innerHeight - heightDistance);
  const [minAnimation, setMinAnimation] = React.useState(true);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const casesLoaded: boolean = patientCareStore.getCasesLoaded;
  // const [casesList, setCasesList] = useState([]);

  useEffect((): void => {
    handleScreenResize();

    setTimeout(() => {
      setMinAnimation(false);
    }, settings.minAnimation);

    /**
     * @description call resize scroll evvents
     */
    window.addEventListener('resize', handleScreenResize);

    /**
     * @description get dom entry selector
     */
    const caseTable = document.querySelector('.ant-table-body');

    /**
     * @description add eventlistener scroll event
     */
    let oldValue = 0;
    let newValue = 0;

    caseTable?.addEventListener('scroll', async () => {
      if (patientCareStore.casesLoadedMore) {
        return;
      }

      if (patientCareStore.casesLoadedMore) {
        // setCasesLoadedMore(true);
      } else {
        const { allCasesLoaded } = patientCareStore;

        newValue = caseTable.scrollTop;

        if (!allCasesLoaded) {
          const perc =
            (caseTable.scrollTop /
              (caseTable.scrollHeight - caseTable.clientHeight)) *
            100;
          if (perc >= 85 && oldValue < newValue) {
            // await this.loadMoreContent();
            await patientCareStore.getMoreCases();
            //  setCasesLoadedMore(true);
          }
        }
      }

      oldValue = newValue;
    });
  });

  useEffect((): void => {
    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);
  });

  /**
   * @description handleScreenResize
   */
  const handleScreenResize = (): void => {
    const contentHeight: any = document.querySelector('.about-wrapper');
    setHeight(contentHeight?.clientHeight - heightDistance);
  };

  /**
   *
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  function onChange(
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ): void {
    console.log('params', pagination, filters, sorter, extra);
  }

  /**
   * @description get template
   */
  const columns = !casesLoaded
    ? casesSkeletonTableTemplate(t)
    : casesTableTemplate(t);

  /**
   * @decription getter
   */
  const casesList: any = patientCareStore.getCases;

  /**
   * @descriiption open all warningbanners filter open list
   */
  const warnRowIds = expandedRowKeys(
    casesList.filter((i: any) => {
      const warnings = i.currentTherapyPlan && i.currentTherapyPlan.warnings;
      const filteredWarnings =
        !isEmpty(warnings) &&
        warnings.length > 0 &&
        warnings.filter(
          (warning: any) =>
            !!warning.reason ||
            (!isEmpty(warning.reason) &&
              warning.reason.reasonState &&
              warning.reason.reasonState !== 'ENTERED-IN-ERROR')
        );
      return filteredWarnings && filteredWarnings.length > 0;
    })
  );

  return (
    <Table
      onRow={(r: any) => ({
        onClick: () =>
          !minAnimation &&
          casesLoaded &&
          navigateToLink({ caseId: r?.id, patientId: r?.patientId })
      })}
      rowKey={(i) => i?.id}
      scroll={{ y: height, x: 500 }}
      pagination={false}
      columns={columns}
      dataSource={
        casesLoaded
          ? casesList
          : [...Array(settings.casesAnimation.counterSkeleton)].map((_, i) => {
              return {
                id: ++i
              };
            })
      }
      onChange={onChange}
      loading={settings.casesAnimation.spinner && !casesLoaded}
      defaultExpandAllRows={false}
      className={'footata'}
      expandable={{
        expandedRowKeys: minAnimation ? false : warnRowIds || '',
        defaultExpandAllRows: false,
        expandIcon: () => null, // icon
        expandRowByClick: false,
        showExpandColumn: false,
        indentSize: 1,
        columnWidth: '100%',
        expandedRowRender: (entry, index) => {
          return (
            <CasesWarningBanners
              key={index}
              entry={entry}
              casesLoaded={casesLoaded}
              patientCareStore={patientCareStore}
              medicationTherapyStore={medicationTherapyStore}
              parentIndex={index}
            />
          );
        }
      }}
    />
  );
};

export default observer(CasesTable);
