import { Badge, Spin } from 'antd';
import React, { FC, Suspense } from 'react';
import Patient from '../../pages/Patient/Patient';

const Messaging = React.lazy(() => import('../../../messaging/Messaging'));
const Monitoring = React.lazy(() => import('../../../monitoring/Monitoring'));
const Medication = React.lazy(() => import('../../../medication/Medication'));

type IDdetailsTabTemplate = {
  patientCareStore: any;
  messagingStore: any;
  tab: string;
  t: Function;
};

const detailsTabTemplate: any = ({
  patientCareStore,
  messagingStore,
  tab,
  t
}: IDdetailsTabTemplate) => {
  const Fallback = (
    <div
      style={{
        margin: 0,
        padding: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Spin />
    </div>
  );
  const tabsConfig = [
    {
      id: 'patient',
      label: t('tabs:patient'),
      key: 'patient',
      children: <>{tab === 'patient' ? <Patient /> : <></>}</>
    },
    {
      id: 'medication',
      label: t('tabs:medication'),
      key: 'medication',
      children: (
        <>
          {tab === 'medication' && (
            <Suspense fallback={Fallback}>
              <Medication />
            </Suspense>
          )}
        </>
      )
    },
    {
      id: 'monitoring',
      label: t('tabs:monitoring'),
      key: 'monitoring',
      children: (
        <>
          {tab === 'monitoring' && patientCareStore.selectedCase !== null && (
            <Suspense fallback={Fallback}>
              <Monitoring />
            </Suspense>
          )}
        </>
      )
    },
    {
      id: 'communication',
      label: (
        <>
          {t('tabs:communication')}
          {messagingStore.getUnreadMessages > 0 && (
            <Badge
              className="badge-item"
              count={messagingStore.getUnreadMessages}
            />
          )}
        </>
      ),
      key: 'communication',
      children: (
        <>
          {tab === 'communication' && patientCareStore.selectedCase !== null && (
            <Suspense fallback={Fallback}>
              <Messaging />
            </Suspense>
          )}
        </>
      )
    }
  ];
  return tabsConfig;
};

export default detailsTabTemplate;
