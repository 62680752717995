import React, { useState, useEffect, FC } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Descriptions, Select, Tooltip, Button } from 'antd';
import { TherapyState } from '../../services/therapyServices';
import { UndoOutlined } from '@ant-design/icons';
import { useTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ITherapyDetails } from '../../models/components/ITherapyDetails';
import { useParams } from 'react-router-dom';
import i18next from 'i18next';

const { Item } = Descriptions;
const { Option } = Select;

const TherapyDetails: FC<ITherapyDetails> = ({
  medicationTherapyStore,
  currentMedplan
}): JSX.Element => {
  const [allLoaded, setLoadedState] = useState(false);
  const [currentSkip, setSkip] = useState(0);
  const [medicationPlansLoaded, setMedicationPlansLoaded] = useState(false);

  const { t } = useTranslation();
  const { patientId } = useParams();
  const { getPlanData, fetchTherapyPlans } = medicationTherapyStore;
  const { medPlans: therapyPlans, med } = medicationTherapyStore;
  const dateFormat: string =
    i18next.language === 'de' ? 'DD.MM.YYYY' : 'YYYY-MM-DD';

  const doSelect = (medplanId: string, option: any) => {
    // reset data
    medicationTherapyStore.clearObservations();
    medicationTherapyStore.setAlarmtTresholdReviewDaySideEffects([]);
    medicationTherapyStore.setAlarmtTresholdReviewDayVitalData([]);
    // reset ui
    medicationTherapyStore.setObservationsSideEffectsOpenItems([]);
    medicationTherapyStore.setObservationsVitalDataOpenItems([]);
    medicationTherapyStore.setObservationsMedicationOpenItems([]);
    // load data
    medicationTherapyStore.changeCurrentMedPlan(medplanId);
    medicationTherapyStore.getPlanData(patientId, medplanId);
    medicationTherapyStore.fetchMedication(medplanId);

    medicationTherapyStore.fetchVitalDataSettings(patientId, medplanId);
    medicationTherapyStore.fetchAlarmtTresholdReviewDayVitalData(
      medplanId,
      patientId
    );
    medicationTherapyStore.fetchSideEffectsSettings(patientId, medplanId);
    medicationTherapyStore.fetchAlarmtTresholdReviewDaySideEffects(
      medplanId,
      patientId
    );

    /**
     * @description init loading therapy details
     */
    //getPlanData({ selectedCase, medPlanId: option.key, type: 'therapy' });
  };

  const {
    id,
    cycle,
    startAt,
    endAt,
    nrDaysOfCycle,
    attendingPhysicianName,
    status,
    statusHistory
  } = !isEmpty(currentMedplan) && currentMedplan;

  const listOfPlans = medicationTherapyStore.getTherapyPlansList;

  /**
   * @description update medplans
   */
  const udpdatePlans = async () => {
    const updateSkip = currentSkip + 1;
    await fetchTherapyPlans({ patientId, skip: updateSkip });
    setSkip(updateSkip);
    setMedicationPlansLoaded(true);
  };

  useEffect(() => {
    // each call will take/retriev 15 plans of available, so if the follow check is true
    // then the maxium size of elements have be retrieved provided by the BE/system
    // if the number is false, then all available data has been called
    // note: 15 is the take amount per call
    const dividable = listOfPlans && listOfPlans.length % 15 === 0;
    therapyPlans !== undefined &&
      (isEmpty(listOfPlans) || !dividable) &&
      setLoadedState(true);
  }, [listOfPlans, therapyPlans]);

  const medplanList = medicationTherapyStore.getTherapyPlansList;

  return (
    <>
      {!isEmpty(currentMedplan) && medplanList.length > 0 ? (
        <>
          <Descriptions
            style={{ display: 'flex', flexDirection: 'row' }}
            className="dtb-select-medplan"
          >
            <Item label={t('therapy:name')} className="dtb-selected-medplan">
              <Select
                defaultValue={id}
                style={{ width: '100%', display: 'inline' }}
                popupClassName="select-plan-item"
                onSelect={doSelect}
                dropdownRender={(dropDownData) => (
                  <>
                    {dropDownData}
                    {!medicationPlansLoaded && (
                      <Button
                        key={'send'}
                        className="btn-load-more"
                        onMouseDown={(e) => e.preventDefault()}
                        onClick={udpdatePlans}
                        type="link"
                        icon={<UndoOutlined />}
                      >
                        {t('therapy:loadMore')}
                      </Button>
                    )}
                  </>
                )}
              >
                {medplanList.length > 0
                  ? medplanList.map((item: any) => {
                      const { title, cycle, startAt, endAt } = item;
                      const state = TherapyState({ startAt, endAt });
                      const toolTipData = [
                        { title: t('therapy:name'), value: title },
                        { title: t('therapy:cycle'), value: cycle },
                        {
                          title: t('therapy:state'),
                          value: t(`therapy:${state}`)
                        },
                        {
                          title: t('therapy:startedAt'),
                          value: moment(startAt).format(dateFormat)
                        },
                        {
                          title: t('therapy:endAt'),
                          value: moment(endAt).format(dateFormat)
                        }
                      ];

                      return (
                        <Option className="select-option" key={item.id}>
                          <Tooltip
                            overlayClassName="therapy-tooltip"
                            placement="left"
                            title={toolTipData.map((item, index) => (
                              <div key={index}>
                                <Descriptions.Item
                                  label={item.title}
                                  style={{ width: '100%' }}
                                >
                                  {item.title}:{' '}
                                  {item.title === 'Status'
                                    ? t(`statuscodes:${item.value}`)
                                    : item.value}
                                </Descriptions.Item>
                                <br />
                              </div>
                            ))}
                          >
                            {title + ', ' + cycle}
                          </Tooltip>
                        </Option>
                      );
                    })
                  : t('therapy:noData')}
              </Select>
            </Item>
            <Item
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
              label={t('therapy:cycle')}
            >
              {cycle}
            </Item>

            <Item
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
              label={t('therapy:status')}
            >
              {t(`statuscodes:${status}`)}
            </Item>

            <Item
              label={t('therapy:nrDaysOfCycle')}
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
            >
              {nrDaysOfCycle + ' ' + t('therapy:days') || '-'}
            </Item>

            <Item
              label={t('therapy:startedAt')}
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
            >
              {moment(startAt).format(dateFormat)}
            </Item>

            <Item
              label={t('therapy:endAt')}
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
            >
              {moment(endAt).format(dateFormat)}
            </Item>

            <Item
              label={t('therapy:attendingPhysicianName')}
              style={{ margin: 0, padding: 0, lineHeight: 0 }}
            >
              &nbsp;
            </Item>
            <Item>{attendingPhysicianName || '-'}</Item>
          </Descriptions>
        </>
      ) : (
        t('therapy:noData')
      )}
    </>
  );
};

export default withTranslation('core')(React.memo(observer(TherapyDetails)));
