import React, { FC } from 'react';
import { Avatar, Card, List, Skeleton, Typography } from 'antd';
import moment from 'moment';
import { observer } from 'mobx-react';
import { ICaseDetailHeader } from '../../models/components/ICaseDetailHeader';
import { useTranslation } from 'react-i18next';
import CaseTreatment from '../CaseTreatment/CaseTreatment';
import CaseCardPatient from '../CaseCardPatient/CaseCardPatient';

const { Text } = Typography;

const CaseDetailHeader: FC<ICaseDetailHeader> = ({
  patientCareStore,
  showSkeleton
}: ICaseDetailHeader): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className={'dtb-detail-header'}>
      <div
        className={'dtb-detail-header-split-item'}
        style={{ margin: 0, padding: 0 }}
      >
        <CaseCardPatient
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={'default'}
        />
      </div>
      <div className={'dtb-detail-header-split-item'}>
        <CaseTreatment
          patientCareStore={patientCareStore}
          showSkeleton={showSkeleton}
          size={'default'}
        />
      </div>
    </div>
  );
};

export default observer(CaseDetailHeader);
