import { Avatar, Row, Col, Skeleton, Badge } from 'antd';
import React from 'react';

const casesSkeletonTableTemplate = (t: Function) => {
  const columns: any = [
    {
      title: t(`casestable:patient`),
      fixed: 'left',
      dataIndex: 1,
      key: 1,
      // responsive: ['sm'],
      width: 200,
      render: (entry: any) => {
        return (
          <Row>
            <Col>
              <Avatar
                size={40}
                style={{
                  display: 'inline-block',
                  textAlign: 'left',
                  float: 'left'
                }}
              ></Avatar>
              <div
                style={{
                  width: '120px',
                  overflow: 'hidden',
                  marginLeft: 10,
                  paddingLeft: 10
                }}
              >
                <Skeleton.Input
                  style={{ width: '100%', height: 14 }}
                  active={true}
                />
                <div style={{ width: '100px', overflow: 'hidden' }}>
                  <Skeleton.Input
                    style={{ marginTop: '5px', width: '100%', height: 14 }}
                    active={true}
                  />
                </div>
              </div>
            </Col>
          </Row>
        );
      }
    },
    {
      title: t(`casestable:medInTake`),
      align: 'center',
      dataIndex: 2,
      key: 2,
      width: 170,
      minWidth: 220,
      // responsive: ['sm'],
      render: (entry: any) => {
        return (
          <div
            style={{
              width: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 40,
              margin: 'auto'
            }}
          >
            <Badge
              className="ant-badge ant-badge-not-a-wrapper"
              count={1}
              style={{ backgroundColor: '#ccc', color: '#ccc' }}
            />
          </div>
        );
      }
    },
    {
      title: t('casestable:therapyPlan'),
      width: 400,
      dataIndex: 3,
      key: 3,
      render: (entry: any) => {
        return (
          <div style={{ width: '400px', overflow: 'hidden' }}>
            <Skeleton.Input
              style={{ marginTop: '10px', width: '300px' }}
              active={true}
            />
          </div>
        );
      }
    },
    {
      title: t('casestable:therapyCycle'),
      dataIndex: 4,
      key: 4,
      align: 'center',
      // responsive: ['sm'],
      width: 140,
      render: (entry: any) => {
        return (
          <div
            style={{
              width: '130px',
              alignItems: 'center',
              alignContent: 'center',
              textAlign: 'center',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              style={{
                width: '30px',
                display: 'inline',
                overflow: 'hidden'
              }}
            >
              <Skeleton.Input
                style={{ marginTop: '10px', width: '30px' }}
                active={true}
              />
            </div>
          </div>
        );
      }
    },
    {
      title: 'Tag',
      dataIndex: 5,
      key: 5,
      // responsive: ['sm'],
      width: 60,
      render: (entry: any) => {
        return (
          <div style={{ width: '30px', overflow: 'hidden' }}>
            <Skeleton.Input
              style={{ marginTop: '10px', width: '30px' }}
              active={true}
            />
          </div>
        );
      }
    },
    {
      title: 'Nebenwirkungen',
      dataIndex: 6,
      key: 6,
      align: 'center',
      // responsive: ['sm'],
      width: 160,
      render: (entry: any) => {
        return (
          <div
            style={{
              width: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 40,
              margin: 'auto'
            }}
          >
            <Badge
              className="ant-badge ant-badge-not-a-wrapper"
              count={1}
              style={{ backgroundColor: '#ccc', color: '#ccc' }}
            />
          </div>
        );
      }
    },
    {
      title: 'Chat',
      dataIndex: 7,
      key: 7,
      align: 'center',
      // responsive: ['sm'],
      width: 100,
      render: (entry: any) => {
        return (
          <div
            style={{
              width: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: 40,
              margin: 'auto'
            }}
          >
            <Badge
              count={1}
              style={{ backgroundColor: '#ccc', color: '#ccc' }}
            />
          </div>
        );
      }
    }
  ];

  return columns;
};

export default casesSkeletonTableTemplate;
