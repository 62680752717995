import React, { FC, useEffect, useState } from 'react';
import { Table } from 'antd';
import { observer } from 'mobx-react';
import casesSkeletonTableTemplate from '../../../templates/tables/casesSkeletonTableTemplate';
import casesTableTemplate from '../../../templates/tables/casesTableTemplate';
import { useTranslation } from 'react-i18next';
import settings from '../../../config/settings';
import { ICasesArchiveTable } from '../../../models/components/ICasesArchiveTable';
import { useNavigate } from 'react-router-dom';
import { expandedRowKeys } from '../../OnkoTableExpandesRows/OnkoTableExpandesRows';
import { isEmpty } from 'lodash';
import CasesWarningBanners from '../../CasesWarningBanners/CasesWarningBanners';

const CasesArchiveTable: FC<ICasesArchiveTable> = ({
  patientCareStore,
  medicationTherapyStore
}): JSX.Element => {
  const heightDistance = settings.casesTableHeight;
  const [height, setHeight] = useState(window.innerHeight - heightDistance);
  const [minAnimation, setMinAnimation] = React.useState(true);

  const { t } = useTranslation();
  const navigate = useNavigate();

  /**
   * @decription getter
   */
  const casesList: any = patientCareStore.getCasesArchive;
  const casesArchiveLoaded: boolean = patientCareStore.getCasesArchiveLoaded;

  /**
   *
   * @param pagination
   * @param filters
   * @param sorter
   * @param extra
   */
  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    console.log('params', pagination, filters, sorter, extra);
  }

  /**
   * @description handleScreenResize event
   */
  const handleScreenResize = () => {
    const fooo: any = document.querySelector('.ant-layout-content');
    setHeight(fooo.clientHeight - heightDistance);
  };

  useEffect(() => {
    handleScreenResize();
    setTimeout(() => {
      setMinAnimation(false);
    }, settings.minAnimation);
    window.addEventListener('resize', handleScreenResize);
  });

  /**
   *
   * @description navigate to link
   * @param param0
   */
  const navigateToLink = ({
    caseId,
    patientId
  }: {
    caseId: string;
    patientId: string;
  }) => {
    navigate(`/archive/patient/${patientId}/case/${caseId}/monitoring`);
  };

  /**
   * @description get template
   */
  const columns = !casesArchiveLoaded
    ? casesSkeletonTableTemplate(t)
    : casesTableTemplate(t);

  /**
   * @descriiption open all warningbanners filter open list
   */
  const warnRowIds = expandedRowKeys(
    casesList.filter((i: any) => {
      const warnings = i.currentTherapyPlan && i.currentTherapyPlan.warnings;
      const filteredWarnings =
        !isEmpty(warnings) &&
        warnings.length > 0 &&
        warnings.filter(
          (warning: any) =>
            !!warning.reason ||
            (!isEmpty(warning.reason) &&
              warning.reason.reasonState &&
              warning.reason.reasonState !== 'ENTERED-IN-ERROR')
        );
      return filteredWarnings && filteredWarnings.length > 0;
    })
  );

  return (
    <Table
      onRow={(r: any) => ({
        onClick: () =>
          !minAnimation &&
          casesArchiveLoaded &&
          navigateToLink({ caseId: r?.id, patientId: r?.patientId })
      })}
      rowKey={(i: any) => i?.id}
      scroll={{ y: height, x: 500 }}
      pagination={false}
      columns={columns}
      dataSource={
        casesArchiveLoaded
          ? casesList
          : [...Array(settings.casesAnimation.counterSkeleton)].map((_, i) => {
              return {
                id: ++i
              };
            })
      }
      onChange={onChange}
      loading={settings.casesAnimation.spinner && !casesArchiveLoaded}
      key="loading-done"
      defaultExpandAllRows={true}
      expandable={{
        defaultExpandedRowKeys: warnRowIds,
        expandedRowKeys: minAnimation ? false : warnRowIds || '',
        defaultExpandAllRows: true,
        expandIcon: () => null, // icon
        expandRowByClick: false,
        showExpandColumn: false,
        // rowExpandable: true,
        expandedRowRender: (entry: any, index) => {
          return (
            <>
              {entry.hasOwnProperty('warnings') && (
                <CasesWarningBanners
                  entry={entry}
                  casesLoaded={casesArchiveLoaded}
                  patientCareStore={patientCareStore}
                  medicationTherapyStore={medicationTherapyStore}
                  parentIndex={index}
                />
              )}
            </>
          );
        }
      }}
    />
  );
};

export default observer(CasesArchiveTable);
