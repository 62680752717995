import React from 'react';
import { MenuProps } from 'antd';
import { Menu } from 'antd';
import { UnorderedListOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Sider = ({ theme = 'dark' }: any) => {
  const [current, setCurrent] = React.useState('1');
  const { t } = useTranslation();

  const onClick: MenuProps['onClick'] = (e): void => {
    setCurrent(e.key);
  };

  return (
    <Menu
      theme={theme}
      onClick={onClick}
      style={{
        width: 200,
        height: 'auto',
        display: 'block'
      }}
      defaultOpenKeys={['sub1']}
      selectedKeys={[current]}
      mode="inline"
      items={[
        {
          key: '1',
          label: <Link to="/cases">{t('coreNav:treatment')}</Link>,
          icon: <UnorderedListOutlined />
        },
        {
          key: '2',
          label: <Link to="/archive">{t('coreNav:archive')}</Link>,
          icon: <DeleteOutlined />
        }
      ]}
    />
  );
};

export default Sider;
